import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import AboveFooterSection from '../../components/AboveFooterSection'
import Layout from '../../components/Layout'
import HeaderSEO from '../../components/partials/HeaderSEO'
import { StaticImage } from 'gatsby-plugin-image'

const arityApacheKafkaCostsAmazonMsk = () => {
    const data = useStaticQuery(graphql`
        query AritySEO {
            allContentfulPages(
                filter: { pageReference: { pageIdentifier: { eq: "ArityCaseStudy" } } }
            ) {
                edges {
                    node {
                        title {
                            title
                        }
                        keywords {
                            keywords
                        }
                        description {
                            description
                        }
                        oGimage {
                            fluid(quality: 100) {
                                src
                            }
                        }
                    }
                }
            }
        }
    `)

    const pageSEO = data.allContentfulPages.edges[0].node

    return (
        <Layout newsletter={false}>
            <HeaderSEO pageSEO={pageSEO} />
            <section>
                <div className='container-1 hero-margin'>
                    <div className='d-flex flex-wrap justify-content-center mobile-padding-top-half'>
                        <div className='col-lg-9 col-md-12 col-12 blog-content-parent '>
                            <div className='text-center'>
                                <StaticImage
                                    src='../../resources/images/case_studies/arity/arity-logo.svg'
                                    quality={100}
                                    alt='Arity'
                                    placeholder='tracedSVG'
                                />
                            </div>
                            <div className='w-75 mx-auto mt-4 mb-3'>
                                <h1 className='text-center my-2 lh-2-3'>
                                    {`Revving Up Arity's Operational Kafka Efficiency by 200% with Lenses.io and Amazon MSK`}
                                </h1>
                            </div>
                            <p className='f-20 text-center px-5'>
                                How Arity accelerated their Kafka adoption and saved costs by 40%.
                            </p>
                            <div className='d-flex justify-content-center mb-3 mt-4'>
                                <StaticImage
                                    src='../../resources/images/case_studies/arity/hero-images_arity.jpg'
                                    quality={100}
                                    placeholder='tracedSVG'
                                    alt='Arity hero'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='bg-cyan mobile-margin-top mt-5'>
                <div className='container-1 py-5'>
                    <div className='d-flex flex-wrap justify-content-around'>
                        <div className='col-sm col-12 px-4 text-center'>
                            <h2 className='f-32 fw-900'>40% operational cost reduction</h2>
                            <p>In operational overhead and personnel</p>
                        </div>
                        <div className='col-sm col-12 px-4 text-center mobile-margin-top'>
                            <h2 className='f-32 fw-900'>3 Kafka clusters</h2>
                            <p>In less than 5 months</p>
                        </div>
                        <div className='col-sm col-12 px-4 text-center mobile-margin-top'>
                            <h2 className='f-32 fw-900'>25% reduction</h2>
                            <p>In service tickets</p>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container-1 mobile-margin-top mt-5'>
                    <div className='d-flex flex-wrap justify-content-center '>
                        <div className='col-sm-9 col-12'>
                            <p>
                                Arity is a mobility data and analytics company that uses telematics
                                data to better understand and predict driving behavior. Arity
                                collects and analyzes driving data from over 30 million unique users
                                to help insurance companies, automobile manufacturers, retail
                                brands, municipalities, and others manage risk and operate more
                                safely.
                            </p>
                            <h3 className='text-center mt-4 mb-2 mobile-margin-top'>
                                The challenge: Supporting 150+ developers and self-managing Kafka
                                was impacting productivity
                            </h3>
                            <p>
                                The Arity DevOps team was spending too much time managing and
                                scaling their self-run Apache Kafka clusters, and servicing support
                                tickets from engineering teams developing on Kafka. This was
                                impacting Arity’s ability to grow their adoption of real-time data
                                streams. The goal was clear: reduce DevOps being the bottleneck,
                                give more self-service control to developers, and remove the need to
                                manage Kafka infrastructure.
                            </p>
                            <h3 className='text-center mt-4 mb-2 mobile-margin-top'>
                                The solution: embracing a Kafka managed service with a leading
                                Developer Experience
                            </h3>
                            <p>
                                {`Enter Lenses.io and Amazon MSK. This powerful duo offered Arity a fully managed solution with Lenses for the Developer Experience building event-driven applications on Kafka, transforming their streaming platform and data operations. Arity's developers gained self-service visibility into the performance of applications & ability to explore data without needing to raise support tickets to DevOps.`}
                            </p>
                            <p>
                                By eliminating the operational burden required to provision servers,
                                orchestrate patches and upgrades, Amazon MSK further freed up
                                technical teams and resources to focus on application development
                                and business results.
                            </p>

                            {/*<div className="bg-white text-left p-4 position-relative">*/}
                            {/*    <p className="f-19 quote-line-height mb-1">*/}
                            {/*        <i>*/}
                            {/*            “Whether it’s migration, security, or a new functionality that can improve our business, Lenses.io and AWS are always there for us. They're two very good partners.”*/}
                            {/*        </i>*/}
                            {/*    </p>*/}
                            {/*    <p className="primary-text fw-600">*/}
                            {/*        <i>Phil Falkenholm, Director of Infrastructure and Security, Arity</i>*/}
                            {/*    </p>*/}
                            {/*    <div>*/}
                            {/*        <div className="casestudy-quotes">*/}
                            {/*            <StaticImage*/}
                            {/*                src="../../resources/images/vectors/quotes.svg"*/}
                            {/*                placeholder="tracedSVG"*/}
                            {/*                alt="quotes"*/}
                            {/*            />*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </section>

            <section className='bg-usecase-arity py-5 mobile-margin-top mt-4'>
                <div className='container-1 py-5'>
                    <div className='d-flex flex-wrap justify-content-center'>
                        <div className='col-sm-8 col-12'>
                            <p className='f-20 text-white text-center mb-0 '>
                                {`“Whether it’s migration, security, or a new functionality that can improve our business, Lenses.io and AWS are always there for us. They're two very good partners.”`}
                            </p>

                            <div className='mt-4 text-center'>
                                <div className='d-flex flex-wrap align-items-center justify-content-center'>
                                    {/*<StaticImage*/}
                                    {/*    src="../../resources/images/case_studies/arityApacheKafkaCostsAmazonMsk/background-testimonial.jpg"*/}
                                    {/*    quality={100}*/}
                                    {/*    alt="Laurent Pernot"*/}
                                    {/*    placeholder="tracedSVG"*/}
                                    {/*/>*/}
                                    <p className='text-white fw-700 pl-3 mt-2 mobile-padding-reset'>
                                        Phil Falkenholm, Director of Infrastructure and Security,
                                        Arity
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container-1 mt-5'>
                    <div className='d-flex flex-wrap justify-content-center'>
                        <div className='col-sm-9 col-12'>
                            <h3 className='text-center mt-4 mb-2 mobile-margin-top'>
                                The transformation: A smooth, fast Kafka migration
                            </h3>

                            <p>
                                {`Lenses offered Arity visibility into their applications connected to Kafka offered Lenses as they were being migrated. As well as the operational savings of MSK & managing Kafka, data observability with Lenses was a game-changer. Within five months, Arity successfully migrated three Kafka clusters, housing hundreds of applications and topics, to Amazon MSK. This move wasn't just about changing platforms; it was about upgrading their entire way of working with streaming data.`}
                            </p>
                            <h3 className='text-center mt-4 mb-2 mobile-margin-top'>
                                The benefits: More than just efficiency
                            </h3>
                            <p>
                                {`A 200% improvement in operational efficiency and a 40% cut in costs were just the tip of the iceberg. The real win was in how Arity's teams could now redirect their focus towards enhancing applications and driving more business value, thanks to the robustness and ease of managing Kafka through Amazon MSK and Lenses.io.`}
                            </p>

                            <p>
                                This case study was originally published on{' '}
                                <a
                                    rel={'noreferrer'}
                                    href='https://aws.amazon.com/partners/success/arity-lenses-io/'
                                    target={'_blank'}>
                                    aws.amazon.com
                                </a>{' '}
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <AboveFooterSection
                text={
                    <>
                        Ready to do the same? <br />
                        <br />
                        Try Lenses on your Amazon MSK:
                    </>
                }
                ctaText={'Get started'}
                ctaLink='/start/'
            />
        </Layout>
    )
}

export default arityApacheKafkaCostsAmazonMsk
